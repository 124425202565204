"use client";

import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";

export const HandleRouteChange = () => {
  const pathname = usePathname();
  const initialPathnameRef = useRef(pathname);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      pathname !== initialPathnameRef.current
    ) {
      window.location.reload();
    }
  }, [pathname]);
  return null;
};
