import(/* webpackMode: "eager" */ "/root/blwall-ru/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/screens/NotFound/page.module.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/shared/images/logo-dark.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/shared/images/logo-white.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/shared/images/page-not-found.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/shared/images/telegram (12) 1.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/shared/images/whatsapp (7) 1.webp");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/shared/lib/AnimationControls.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HandleRouteChange"] */ "/root/blwall-ru/shared/lib/HandleRouteChange.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/shared/scripts/CookieConsentComponent.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/shared/ui/Logo/Logo.css");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/root/blwall-ru/shared/ui/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/shared/ui/TermsAndConditions/TermsAndConditions.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/widgets/Footer/Footer.css");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/widgets/Header/HeaderCTABtn/HeaderCTABtn.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/widgets/Header/HeaderLanguageSelector/HeaderLanguageSelector.tsx");
;
import(/* webpackMode: "eager" */ "/root/blwall-ru/widgets/Header/page.module.css");
